import NightlightIcon from "@mui/icons-material/Nightlight";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import WbTwilightIcon from "@mui/icons-material/WbTwilight";

import { ShiftPeriod } from "../Shifts/Shift/types";

export const shiftPeriodLabels = [ShiftPeriod.AM, ShiftPeriod.PM, ShiftPeriod.NOC];

export const shiftPeriodColors = {
  [ShiftPeriod.AM]: { color: "#57afef" },
  [ShiftPeriod.PM]: { color: "#0c71ba" },
  [ShiftPeriod.NOC]: { color: "#375f7d" },
};

export const bookedShiftColor = "#2ecc71";
export const priorityAccessColor = "#6a67ce";

export const shiftPeriodIcons = {
  [ShiftPeriod.AM]: WbSunnyIcon,
  [ShiftPeriod.PM]: WbTwilightIcon,
  [ShiftPeriod.NOC]: NightlightIcon,
};

export const OPEN_SHIFT_LIST_PAGE_SIZE = 50;
